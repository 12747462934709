import type { AsyncState } from "@react-hookz/web";
import { atom } from "jotai";

import type { AdvertisementCampaignConfig } from "~/components/advertisement/campaign/hooks";
import { defaultAdvertisementCampaignConfig } from "~/components/advertisement/campaign/hooks";
import type { AdvertisementConfig } from "~/components/advertisement/hooks";
import { defaultAdvertisementConfig } from "~/components/advertisement/hooks";
import type { IpGeoLocation } from "~/lib/hooks";

export interface App {
  advertisementCampaignConfig: AdvertisementCampaignConfig;
  advertisementConfig: AdvertisementConfig;
  ipGeoLocation?: AsyncState<IpGeoLocation | undefined>;
  lockDocumentBodyScroll?: boolean;
}

export const appAtom = atom<App>({
  advertisementCampaignConfig: defaultAdvertisementCampaignConfig,
  advertisementConfig: defaultAdvertisementConfig,
  lockDocumentBodyScroll: false,
});
