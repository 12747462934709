import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAsync, useDeepCompareEffect, useMountEffect } from "@react-hookz/web";
import defaults from "lodash/defaults";
import { useState } from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { config } from "~/data";
import type { hooksAdvertisementConfigQuery } from "~/queries/__generated__/hooksAdvertisementConfigQuery.graphql";

export const useAdvertisementConfig = () => {
  const { set: setCachedConfig, value: cachedConfig } =
    useFallbackStorageValue<RawAdvertisementConfig>("advertisement-config");

  const [resolvedConfig, setResolvedConfig] = useState<AdvertisementConfig>(
    parseConfig(cachedConfig) ?? defaultAdvertisementConfig,
  );
  const environment = useRelayEnvironment();
  const [{ result: remoteConfig }, { execute }] = useAsync(async () => {
    // Set config in drupal
    // Ref: https://${drupal-host}/admin/config/services/app-config
    // Example: https://staging-scmp-d8.product-web.dev-2.scmp.tech/admin/config/services/app-config
    const data = await fetchQuery<hooksAdvertisementConfigQuery>(
      environment,
      graphql`
        query hooksAdvertisementConfigQuery($id: String) {
          appConfig(filter: { entityId: $id }) {
            json
          }
        }
      `,
      {
        id: config.advertisement.configId,
      },
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    return data.appConfig.json as RawAdvertisementConfig;
  });
  useMountEffect(execute);

  useDeepCompareEffect(() => {
    if (!remoteConfig) return;

    setResolvedConfig(parseConfig(remoteConfig) ?? defaultAdvertisementConfig);
    setCachedConfig(remoteConfig);
  }, [remoteConfig, setCachedConfig]);

  return resolvedConfig;
};

export type AdvertisementConfig = {
  autoRefresh: {
    desktopMaximumRefreshLimit: number;
    disabledCountries: string[];
    minimalScrollAwayDurationMs: number;
    mobileMaximumRefreshLimit: number;
    refreshIntervalMs: number;
  };
  viewabilityTracking: {
    enable: boolean;
    eventIntervalMs: number;
    testMode: {
      enable: boolean;
      targetUrlPatterns: string[];
    };
    workerIntervalMs: number;
  };
};

export const defaultAdvertisementConfig: AdvertisementConfig = {
  autoRefresh: {
    desktopMaximumRefreshLimit: 30,
    disabledCountries: [],
    minimalScrollAwayDurationMs: 5000,
    mobileMaximumRefreshLimit: 5,
    refreshIntervalMs: 20_000,
  },
  viewabilityTracking: {
    enable: false,
    eventIntervalMs: 10_000,
    testMode: {
      enable: false,
      targetUrlPatterns: [],
    },
    workerIntervalMs: 15_000,
  },
};

type RawAdvertisementConfig = {
  adViewabilityTrackingEnable?: number;
  adViewabilityTrackingEventInterval?: number;
  adViewabilityTrackingQueueWorkerInterval?: number;
  adViewabilityTrackingTargetUrls?: string[];
  adViewabilityTrackingTestMode?: number;
  autoRefreshDesktopLimit?: number;
  autoRefreshInterval?: number;
  autoRefreshMinimalScrollAwayDurationMs?: number;
  autoRefreshMobileLimit?: number;
  disableAutoRefreshCountries?: string[];
};

function parseConfig(raw?: RawAdvertisementConfig | null): AdvertisementConfig | undefined {
  if (!raw) return;

  const parsed: DeepPartial<AdvertisementConfig> = {
    autoRefresh: {
      desktopMaximumRefreshLimit: raw.autoRefreshDesktopLimit,
      disabledCountries: raw.disableAutoRefreshCountries,
      minimalScrollAwayDurationMs: raw.autoRefreshMinimalScrollAwayDurationMs,
      mobileMaximumRefreshLimit: raw.autoRefreshMobileLimit,
      refreshIntervalMs: raw?.autoRefreshInterval && raw.autoRefreshInterval * 1000,
    },
    viewabilityTracking: {
      enable: raw.adViewabilityTrackingEnable === 1,
      eventIntervalMs: raw.adViewabilityTrackingEventInterval,
      testMode: {
        enable: raw.adViewabilityTrackingTestMode === 1,
        targetUrlPatterns: raw.adViewabilityTrackingTargetUrls,
      },
      workerIntervalMs: raw.adViewabilityTrackingQueueWorkerInterval,
    },
  };

  const merged = {
    autoRefresh: defaults(parsed.autoRefresh, defaultAdvertisementConfig.autoRefresh),
    viewabilityTracking: defaults(
      parsed.viewabilityTracking,
      defaultAdvertisementConfig.viewabilityTracking,
    ),
  };

  return merged;
}
