/* eslint-disable max-lines */
import type {
  GaSlideImpressionType,
  OnboardUserType,
} from "~/components/popup-container/onboarding/types";

export type Event =
  | AcquisitionEvents
  | AcquisitionNewsletterSignUpEvent
  | AppDownloadEvents
  | ArticleEntityLinkEvent
  | ArticleGalleryWidgetEvents
  | BookmarkEvents
  | CommentButtonEvents
  | FooterClickEvents
  | LoginEvents
  | MenuEvents
  | MoreOnThisWidgetEvents
  | MyAccountEvents
  | NewsletterSignUpEvent
  | NewsTickerEvents
  | NotificationCenterEvents
  | OnboardingPopUpAppDownloadClickEvents
  | OnboardingPopUpAppDownloadEvents
  | OnboardingPopUpBookmarkArticleEvents
  | OnboardingPopUpCommentingEvents
  | OnboardingPopUpCompleteEvents
  | OnboardingPopUpEvergreenEvents
  | OnboardingPopUpFollowEvents
  | OnboardingPopUpImpressionsEvents
  | OnboardingPopUpNewsletterSignUpEvents
  | OnboardingPopUpRecommendedArticleEvents
  | OnboardingPopUpStartEvents
  | OnboardingPopUpSubscribedContinueEvents
  | OnboardingPopUpSubscribedEvents
  | OnboardingPopUpSubscribedRosettaEvents
  | PostiesBrochureHomepageClickEvents
  | PostiesHeaderClickEvents
  | PostiesSubscriptionWidgetClickEvents
  | PostiesEventsDetailClickEvent
  | PostiesEventRegisterClickEvents
  | SeriesWidgetEvents
  | ShareButtonEvents
  | StoryStyleEvents
  | StyleEvents
  | TopPicksBottomEvents
  | TopPicksEvents
  | YouTubeVideoEvents
  | PaywallEvents
  | LiveBlogPushNotificationSwitchEvents
  | LiveBlogPushNotificationAllowEvents
  | PostiesSubscriptionEvents
  | TextToSpeechEvents
  | ArticleReactionEvents
  | HomepageEvents
  | ArticleSpeechEvents;

export type UntypedEvent = {
  action: string;
  category: string;
  label?: string;
};

// Common
type ArticleIndex = number;
type ArticleUrl = string;
type CurrentLocationUrl = string;
type Destination = string;
type Section = string;

type PaywallEvents =
  | PaywallImpressionEvent
  | PaywallCheckoutCustomMeteringEvent
  | PaywallCheckoutCustomSubscriptionEntryPointEvent
  | PaywallCheckoutCustomMeterDirectCheckoutEvent
  | PostiesArticlePaywallImpressionEvent;

type PaywallImpressionEvent = {
  action: `Subscribe to SCMP/${Paywall}/Impression`;
  category: "Subscribe to SCMP";
  label: string;
  value?: string;
};
type Paywall = string;

type PostiesArticlePaywallImpressionEvent = {
  action: `Subscribe to SCMP/Posties paywall/Impression`;
  category: "Subscribe to SCMP";
  label: string;
};

type PaywallCheckoutCustomMeteringEvent = {
  action: `Subscription Metering/${"Login Wall/" | ""}${
    | "Enlarged"
    | "Minimize"}/${PaywallCheckoutCustomMeteringActionEventType}`;
  category: "Subscription Metering";
  label: string;
  value: string;
};

type PaywallCheckoutCustomMeteringActionEventType =
  | "Click Subscribe"
  | "Close"
  | "Sign in"
  | "Impression";

type PaywallCheckoutCustomSubscriptionEntryPointEvent = {
  action: `Subscription Entry Point/Article Footer/${"Close" | "Support Us"}`;
  category: "Subscription Entry Point";
  label: string;
};

type PaywallCheckoutCustomMeterDirectCheckoutEvent = {
  action: string;
  category: "Subscribe to SCMP";
  label: string;
  value: string;
};

export type NewsTickerEvents = {
  action: `${"Impression" | "Click to Open" | "Close"}/${"breaking" | "others"}`;
  category: "Ticker";
  label: ArticleId;
};

export type NewsletterSignUpEvent = {
  action: `Newsletter Sign Up/${string}`;
  category: string;
  label: CurrentPageUrl;
};

export type AcquisitionNewsletterSignUpEvent = {
  action: `Acquisition/${AcquisitionCampaign}/Register/${string}`;
  category: "Acquisition";
  label: CurrentPageUrl;
};

export type OnboardingPopUpAppDownloadEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Download App/Continue to SCMP`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpAppDownloadClickEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Download App/Click ${"Google" | "App"} Store`;
  category: "Onboarding/Pop-up";
  label: "";
};

export type OnboardingPopUpBookmarkArticleEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/${GaSlideImpressionType}/Bookmark`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpCommentingEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Commenting`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpCompleteEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Complete`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpFollowEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Follow`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpEvergreenEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/${GaSlideImpressionType}`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpImpressionsEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/${GaSlideImpressionType}/Impressions`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpNewsletterSignUpEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Newsletter Signup`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpRecommendedArticleEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Recommended Article`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpStartEvents = {
  action: "Onboarding/Pop-up/start";
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpSubscribedEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Subscribe Now`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpSubscribedRosettaEvents = {
  action: `Onboarding/Pop-up/Subscribe Now/${string}`;
  category: "Onboarding/Pop-up";
  label: string;
};

export type OnboardingPopUpSubscribedContinueEvents = {
  action: `Onboarding/Pop-up/${OnboardUserType}/Subscription/Continue`;
  category: "Onboarding/Pop-up";
  label: "";
};

type FooterClickEvents = {
  action: "Footer/Click";
  category: "Footer";
  label: string;
};

type PostiesHeaderClickEvents = {
  action: "Header/Click";
  category: "Header";
  label: string;
};

type PostiesBrochureHomepageClickEvents =
  | PostiesBrochureHomepageEPaperClickEvents
  | PostiesBrochureHomepageEducationClickEvents
  | PostiesBrochureHomepagePromotionBannerClickEvents
  | PostiesBrochureHomepageNewsletterSignUpClickEvent;

type PostiesBrochureHomepagePromotionBannerClickEvents = {
  action: "Promotion Banner/Click";
  category: "Promotion Banner";
  label: string;
};

type PostiesBrochureHomepageEPaperClickEvents = {
  action: "ePaper/Click";
  category: "ePaper";
  label: string;
};

type PostiesBrochureHomepageEducationClickEvents = {
  action: "SCMP Education/Click";
  category: "SCMP Education";
  label: string;
};

type PostiesBrochureHomepageNewsletterSignUpClickEvent = {
  action: "Newsletter Sign Up/Click";
  category: "Newsletter (Brochure)";
};

type PostiesSubscriptionWidgetClickEvents =
  | PostiesSubscriptionWidgetPlanTermToggleClickEvents
  | PostiesSubscriptionWidgetPlansClickEvents
  | PostiesSubscriptionClickableWidgetWithoutLabelClickEvents;

export type PostiesSubscriptionWidgetSection = "Posties paywall" | "Posties brochure";

type PostiesSubscriptionWidgetPlanTermToggleClickEvents = {
  action: `Subscribe to SCMP/${PostiesSubscriptionWidgetSection}/Plan term toggle/Click`;
  category: "Subscribe to SCMP";
  label: "Monthly" | "Yearly";
};

type PostiesSubscriptionWidgetPlansClickEvents = {
  action: `Subscribe to SCMP/${PostiesSubscriptionWidgetSection}/Plans/Click`;
  category: "Subscribe to SCMP";
  label: string;
};

type PostiesSubscriptionClickableWidgetWithoutLabel = "Compare benefits" | "Subscribe" | "Redeem";

type PostiesSubscriptionClickableWidgetWithoutLabelClickEvents = {
  action: `Subscribe to SCMP/${PostiesSubscriptionWidgetSection}/${PostiesSubscriptionClickableWidgetWithoutLabel}/Click`;
  category: "Subscribe to SCMP";
};

export type MenuEvents =
  | LineMenuEvents
  | LineMenuAllEvents
  | LineMenuAllOpenEvent
  | MenuOpenEvent
  | MenuCloseEvent
  | MenuClickEvent;

type LineMenuEvents = {
  action: "Linemenu/Click";
  category: "Menu";
  label: `${MenuEditionName}/Linemenu/Click/${SectionName}`;
};

type LineMenuAllOpenEvent = {
  action: "Linemenu/Click";
  category: "Menu";
  label: `${MenuEditionName}/Linemenu/All/Open`;
};

type QueueName = string;
type SectionName = string;
type LineMenuAllEvents = {
  action: "Linemenu/Click";
  category: "Menu";
  label: `${MenuEditionName}/Linemenu/All/${QueueName}/${SectionName}`;
};

type MenuOpenEvent = {
  action: "Menu/Open";
  category: "Menu";
  label: "Menu/Open";
};

type MenuCloseEvent = {
  action: "Menu/Close";
  category: "Menu";
  label: "Menu/Close";
};

export type PostiesMenuItemName =
  | "Why Posties"
  | "Events"
  | "News"
  | "Subscribe"
  | "Redeem"
  | "Read"
  | "Video"
  | "ePaper"
  | "Little Journalists";

export type PostiesMenuActionButtonLabel = "For Kids" | "For Parents";

export type PostiesSubMenuItemName =
  | "Ha-Ha-Happenings"
  | "Eye on the News"
  | "Big Read"
  | "Things to do"
  | "STEAM studio"
  | "Your say"
  | "Health and happiness";

type PostiesMenuItemLabel =
  | PostiesMenuItemName
  | PostiesMenuActionButtonLabel
  | PostiesSubMenuItemName;

type MenuClickEvent = {
  action: "Menu/Click";
  category: "Menu";
  label:
    | `Menu/Highlights/${MenuText}`
    | `Menu/Subscribe`
    | `Menu/Click/${MenuTabName}`
    | `Menu/Click/${MenuTabName}/${MenuText}`
    | `Menu/Click/${MenuTabName}/${MenuParentText}/${MenuText}`
    | `Menu/Click/${MenuSection}/${"Expand" | "Collapse"}`
    | `Menu/Click/Search/${MenuSearchText}`
    | `Menu/bookmark`
    | `Menu/mynews`
    | `Menu/Search`
    | `Menu/Edition/${MenuEditionName}`
    | PostiesMenuItemLabel;
};

type MenuEditionName = string;
type MenuTabName = string;
type MenuText = string;
type MenuParentText = string;
type MenuSearchText = string;
type MenuSection = string;

export type NotificationCenterEvents =
  | NotificationCenterCompletionEvents
  | {
      action: `Notification Center/Follow/${"Impression" | "Click"}`;
      category: "Notification Center";
      label: CurrentLocationUrl;
    }
  | {
      action: `Notification Center/Follow/${FollowButtonSource}/Click`;
      category: "Notification Center";
      label?: string;
    };

export type NotificationCenterCompletionEvents = {
  action: `Notification Center/${"Follow" | "Unfollow"}/${
    | `${FollowButtonSource}/${FollowType}`
    | `${FollowType}`}`;
  category: "Notification Center";
  label: FollowItemName;
};
export const followButtonSources = [
  "Article_topic_footer",
  "Homepage_gba",
  "HP Topics",
  "Top Story 1",
  "HP Section Block",
  "Trending Topics",
  "Homepage_feature_package",
] as const;
export type FollowButtonSource = (typeof followButtonSources)[number];
type FollowType = "Author" | "Section" | "Topic";
type FollowItemName = string;

export type AcquisitionEvents = AcquisitionFollowEvents;

type AcquisitionFollowEvents = {
  action: `Acquisition/${AcquisitionCampaign}/Follow/${FollowType}`;
  category: "Acquisition";
  label: string;
};

type AcquisitionCampaign = string;

export type LoginEvents = LoginButtonEvents | LoginPopupEvents | LoginSubscriptionMeteringEvents;

type LoginButtonEvents = {
  action: "Login/Click";
  category: "Login";
  label: CurrentPageUrl;
};

type LoginPopupEvents = {
  action: `Login/Pop-up/${"Impression" | "Close"}`;
  category: "Login";
  label: CurrentPageUrl;
};

type LoginSubscriptionMeteringEvents = {
  action: `Subscription Metering/Login Wall/Enlarged/Log In Sign Up Success ${string}`;
  category: "Subscription Metering";
  label: CurrentPageUrl;
};

type CurrentPageUrl = string;
type ArticleId = string;

export type BookmarkEvents = BookmarkHomepageEvents | BookmarkArticleEvents;

type BookmarkHomepageEvents = {
  action: `Bookmark/HomePage/${"Click"}`;
  category: "Bookmark";
};

type BookmarkArticleEvents = {
  action: `Bookmark/Article/${"Click" | "Add" | "Remove"}`;
  category: "Bookmark";
  label: string;
};

type StyleEvents = StyleHomePageEvents;

type StyleHomePageEvents = StyleHomePageDepthEvent;

export type StyleHomePageDepthEvent = {
  action: `Impression/${string}`;
  category: "Style Homepage Depth";
};

type StoryStyleEvents =
  | StoryStyleImpressionEvents
  | StoryStyleReadMoreEvents
  | StoryStyleNewsletterClickEvents
  | StoryStyleNewsletterImpressionEvents
  | StoryStyleCardCarouselClickEvents;

type StoryStyleCardCarouselClickEvents = {
  action: `Story Card/Style/Carousel/Click`;
  category: "Story Card";
  label: string;
};

type StoryStyleNewsletterClickEvents = {
  action: `Story Card/newsletter/${Section}/Click`;
  category: "Story Card";
  label: Destination;
};

type StoryStyleNewsletterImpressionEvents = {
  action: `Story Card/${Section}/Impression`;
  category: "Story Card";
  label: "newsletter sign up";
  value: 29;
};

type StoryStyleImpressionEvents = {
  action: `Story Card/${Section}/Impression`;
  category: "Story Card";
  value: ArticleIndex;
};

type StoryStyleReadMoreEvents = {
  action: `Story Card/Read more/${Section}/Click`;
  category: "Story Card";
  label: string;
  value: ArticleIndex;
};

type MyAccountEvents =
  | MyAccountUpdatePaymentEvents
  | MyAccountAppPromotionMessageEvents
  | MyAccountOverlayEvents;
type MyAccountUpdatePaymentEvents = {
  action: `My Account/Overlay/Update Payment/${"Impression" | "Click"}`;
  category: "My Account";
  label: "";
};
type MyAccountAppPromotionMessageEvents = {
  action: `My Account/Overlay/Download or update/Click`;
  category: "My Account";
  label: "";
};
type MyAccountOverlayEvents = {
  action: `My Account/Overlay/Click`;
  category: "My Account";
  label: string;
};

type CommentButtonEvents =
  | CommentButtonImpressionEvents
  | CommentButtonClickEvents
  | CommentButtonLoginSuccessEvents
  | LiveContentCommentButtonClickEvents;

export type CommentButtonImpressionEvents = {
  action: `Comment button/Impression`;
  category: "Comment";
  label: `Comment button/Impression/${CommentButtonLocation}`;
};

export type CommentButtonClickEvents = {
  action: `Comment button/Click`;
  category: "Comment";
  label: `Comment button/Click/${CommentButtonLocation}`;
};
export type CommentButtonLoginSuccessEvents = {
  action: `Comment button/Login/success`;
  category: "Comment";
};

export type LiveContentCommentButtonClickEvents = {
  action: "Share button (Inline)/Comment/Click";
  category: "Share button (Inline)";
  label: CurrentLocationUrl;
};

export type CommentButtonLocation = "Article Bottom" | "Article Side" | "Adhesive";

type ShareButtonEvents = {
  action: "Share menu button/Click";
  category: "Share button (Adhesive)";
  label: CurrentLocationUrl;
};

export type MoreOnThisWidgetEvents =
  | {
      action: `More on this Story/Click`;
      category: "More on this Story";
      label: ArticleUrl;
    }
  | {
      action: `More on this Story/Impression`;
      category: "More on this Story";
      label: "";
    };

export type YouTubeVideoEvents = YoutubeVideoControlEvent | YoutubeVideoTimeEvent | YoutubeAdEvents;

type YoutubeVideoControlEvent = {
  action: `${string}${"Play" | "Pause"}`;
  category: "Youtube video";
  label: string;
};

type YoutubeVideoTimeEvent = {
  action: `${string}Video progress ${string}`;
  category: "Youtube video";
  label: string;
};

type timeOffset = string;
type YoutubeAdEvents =
  | {
      action: `${string}ad/${timeOffset}`;
      category: "Youtube video";
      label: string;
    }
  | {
      action: `${string}ad start`;
      category: "Youtube video";
      label: string;
    };

type ArticleEntityLinkEvent = {
  action: `${"Hard link/Click" | "Inline link/Click"}`;
  category: "Article Inline Link";
  label: string;
};

export type TopPicksEvents = TopPicksImpressionEvent | TopPicksClickEvent;

type TopPicksImpressionEvent = {
  action: `Top Picks/inHouse/${string}/Impression`;
  category: "Top Picks";
  label: string;
};

type TopPicksClickEvent = {
  action: `Top Picks/inHouse/${string}/Click`;
  category: "Top Picks";
  label: string;
};

export type TopPicksBottomEvents = TopPicksBottomImpressionEvent | TopPicksBottomClickEvent;

type TopPicksBottomImpressionEvent = {
  action: `PS_Topic/inHouse/${string}/Impression`;
  category: "Top Picks Bottom";
  label: string;
};

type TopPicksBottomClickEvent = {
  action: `PS_Topic/inHouse/${string}/Click`;
  category: "Top Picks Bottom";
  label: string;
};

export type SeriesWidgetEvents = SeriesWidgetImpressionEvent | SeriesWidgetClickEvent;

// cSpell:ignore Recriculation

type SeriesWidgetImpressionEvent = {
  action: "Series Recirculation Widget/LHS/Impression";
  category: "Series Recriculation Widget";
  label: string;
};

type SeriesWidgetClickEvent = {
  action: "Series Recirculation Widget/LHS/Click";
  category: "Series Recriculation Widget";
  label: string;
};

export type ArticleGalleryWidgetEvents =
  | ArticleGalleryWidgetImpressionAd
  | ArticleGalleryWidgetImpression
  | ArticleGalleryWidgetClick;

type ArticleGalleryWidgetImpressionAd = {
  action: `Article Gallery Widget/photo_gallery/${string}/Impression${"/ads" | ""}`;
  category: "Article Gallery Widget";
  label: `${string} of ${string}`;
};

type ArticleGalleryWidgetImpression = {
  action: "Article Gallery Widget/Impression";
  category: "Article Gallery Widget";
  label: string;
};

type ArticleGalleryWidgetClick = {
  action: "Article Gallery Widget/Click";
  category: "Article Gallery Widget";
  label: string;
};

export type AppDownloadEvents = AppDownloadFbInAppEvents | AppReadFullArticleEvents;

type AppDownloadFbInAppEvents = {
  action: `App Download/FB Inapp browser/${"Click" | "Close"}`;
  category: "App Download";
  label: CurrentLocationUrl;
};

type LiveBlogPushNotificationAllowEvents = {
  action: "Push Notification/Allow";
  category: "Push Notification PWA";
  label: string;
};

type LiveBlogPushNotificationSwitchEvents = {
  action: `Live Blog Push PWA Notification/Switch ${"on" | "off"}`;
  category: "Live Blog Push PWA";
  label: string;
};

// cspell: ignore Artilce
type AppReadFullArticleEvents = {
  action: `App Download/Read Full Artilce/${"Click" | "Impression"}`;
  category: "App Download";
  label: CurrentLocationUrl;
};

type PostiesSubscriptionEvents =
  | PostiesSubscriptionBillingCycleToggleEvent
  | PostiesSubscriptionPlanClickEvent
  | PostiesSubscriptionCompareBenefitClickEvent
  | PostiesSubscriptionSubscribeClickEvent
  | PostiesSubscriptionRedeemClickEvent;

type PostiesSubscriptionBillingCycleToggleEvent = {
  action: `Subscribe to SCMP/${PostiesSubscriptionTriggerPoint}/Plan term toggle/Click`;
  category: "Subscribe to SCMP";
  label: "Monthly" | "Yearly";
};

type PostiesSubscriptionPlanClickEvent = {
  action: `Subscribe to SCMP/${PostiesSubscriptionTriggerPoint}/Plans/Click`;
  category: "Subscribe to SCMP";
  label: PianoTermName;
};
type PianoTermName = string;

type PostiesSubscriptionCompareBenefitClickEvent = {
  action: `Subscribe to SCMP/${PostiesSubscriptionTriggerPoint}/Compare benefits/Click`;
  category: "Subscribe to SCMP";
  label: "";
};

type PostiesSubscriptionSubscribeClickEvent = {
  action: `Subscribe to SCMP/${PostiesSubscriptionTriggerPoint}/Subscribe/Click`;
  category: "Subscribe to SCMP";
  label: "";
};

type PostiesSubscriptionRedeemClickEvent = {
  action: `Subscribe to SCMP/${PostiesSubscriptionTriggerPoint}/Redeem/Click`;
  category: "Subscribe to SCMP";
  label: "";
};

type PostiesEventsDetailClickEvent = {
  action: "Posties Events/Details/Click";
  category: "Posties Events";
  label: string;
};

type PostiesSubscriptionTriggerPoint = "Posties brochure" | "Posties paywall";

type TextToSpeechEvents =
  | {
      action: `TTS/${
        | "Close"
        | "Click"
        | "Play"
        | "Pause"
        | "Progress"
        | "Progress Finished"
        | "Resume"
        | "speed changed"
        | "voice changed"}`;
      category: "TTS";
      label: string;
    }
  | TextToSpeechThreeSecondEvent;

type TextToSpeechThreeSecondEvent = {
  action: `TTS/Speech progress 3s`;
  category: "TTS";
};

type PostiesEventRegisterClickEvents = {
  action: "Posties Events/Register/Click";
  category: "Posties Events";
  label: string;
};

type ArticleReactionEvents = {
  action: `Article Reaction/Like/Click${"/Success" | "/Undo" | ""}`;
  category: "Article Reaction";
  label?: string;
};

type HomepageEvents =
  | HomepageFoldEvent
  | HomepageAiEngineEvent
  | HomepageTopStoryEvents
  | HomepageLatestEvents
  | HomepageFlexiUnitEvents
  | HomepageNewsletterImpressionEvent
  | HomepageThingsToDoEvents
  | HomepageGBAEvents
  | HomepageOpinionEvents
  | HomepageScmpSpotlightEvents
  | HomepageSectionBlockEvents
  | HomepageSubscriptionEvent
  | HomepageMyNewsEvents
  | HomepageMostPopularEvents
  | HomepageFromOurAdvertisersEvents
  | HomepageAroundSCMPEvents
  | HomepageFooterEvents
  | HomepageMultimediaEvent
  | HomepageSuperSplashEvents
  | HomepageTrendingTopicsEvent
  | HomepageStoryPackageEvents;

type HomepageFoldEvent = {
  action: `Impression/${"first" | "second" | "third"}_fold`;
  category: "Homepage Depth";
};

type HomepageTopStoryEvents = {
  action: `${"Click" | "Impression"}/top_story_${
    | "1"
    | "4"
    | "5"
    | "6"
    | "8"
    | "9"
    | "12"
    | "13"
    | "16"
    | "17"}`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageFlexiUnitEvents = {
  action: `${"Click" | "Impression"}/flexi_unit/${string}`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageAiEngineEvent = {
  action: `${"Impression" | "Click"}/${"recommended_for_you" | "you_may_have_missed"}`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageLatestEvents = {
  action: `${"Click" | "Impression"}/${"latest" | "more_latest_news"}`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageNewsletterImpressionEvent = {
  action: `Newsletter Sign Up/Impression`;
  category: "Newsletter (Homepage - Inline)";
  label?: string;
};

type HomepageThingsToDoEvents = {
  action: `${"Click" | "Impression"}/things_to_do_hk`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageGBAEvents = {
  action: `${"Click" | "Impression"}/homepage_gba`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageOpinionEvents = {
  action: `${"Click" | "Impression"}/opinion`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageScmpSpotlightEvents = {
  action: `${"Click" | "Impression"}/scmp_spotlight`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageSectionBlockEvents = {
  action: `${string}/${"Click" | "Impression"}`;
  category: "HP Section Block";
  label?: string;
};

type HomepageSubscriptionEvent = {
  action: "Subscription Entry Point/Homepage inline/Click";
  category: "Subscription Entry Point";
  label?: string;
};

type HomepageMyNewsEvents =
  | {
      action: `Homepage/${`Daily${number}` | "more stories"}/Click`;
      category: "My News";
      label?: string;
    }
  | {
      action: `My News/Homepage/Setup/Click`;
      category: "My News";
    };

type HomepageMostPopularEvents = {
  action: `${"Click" | "Impression"}/most_popular`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageFromOurAdvertisersEvents = {
  action: `${"Click" | "Impression"}/advertisers`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageAroundSCMPEvents = {
  action: `${"Click" | "Impression"}/around_scmp`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageFooterEvents = HomepageFooterDepthEvents | HomepageFooterSubscriptionEntryPointEvents;

type HomepageFooterDepthEvents = {
  action: `${"Click" | "Impression"}/footer`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageFooterSubscriptionEntryPointEvents = {
  action: `Subscription Entry Point/${"Footer" | "Article Footer"}/Impression`;
  category: "Subscription Entry Point";
  label?: string;
};

type HomepageMultimediaEvent = {
  action: `Click/multimedia`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageSuperSplashEvents = {
  action: `${"Click" | "Impression"}/top_story_breaking`;
  category: "Homepage Depth";
  label?: string;
};

type HomepageTrendingTopicsEvent = {
  action: "Click/trending_topics";
  category: "Homepage Depth";
  label?: string;
};

type HomepageStoryPackageEvents = {
  action: `${"Click" | "Impression"}/feature_package`;
  category: "Homepage Depth";
  label?: string;
};

type ArticleSpeechEvents =
  | ArticleSpeechImpressionEvent
  | ArticleSpeechSignInEvent
  | ArticleSpeechSubscriptionEvent;

type ArticleSpeechImpressionEvent = {
  action: "Subscription Entry Point/TTS/Impression";
  category: "Subscription Entry Point";
  label?: string;
};

type ArticleSpeechSubscriptionEvent = {
  action: "Subscription Entry Point/TTS/Subscribe/Click";
  category: "Subscription Entry Point";
  label?: string;
};

type ArticleSpeechSignInEvent = {
  action: "Subscription Entry Point/TTS/Sign in/Click";
  category: "Subscription Entry Point";
  label?: string;
};
