import type { FollowType } from "@product/scmp-sdk";
import { FollowButtonAction, unionArray } from "@product/scmp-sdk";

import { followButtonSources } from "~/components/tracking/google-analytics/types";
import type {
  FollowButtonSource,
  NotificationCenterCompletionEvents,
} from "~/components/tracking/google-analytics/types";
import { typedCapitalize } from "~/lib/utils";

import { FollowTypeKeyMapping } from "./consts";
import type { FollowPayload, GetUserFollowList } from "./types";

export const generateNewList = (
  previousFollowList: GetUserFollowList["userFollowList"] | undefined,
  payload: FollowPayload,
) => {
  const { action, ids, type } = payload;
  const newFollowList = previousFollowList
    ? { ...previousFollowList }
    : { authors: [], sections: [], topics: [] };
  const typeName = FollowTypeKeyMapping[type];
  if (action === FollowButtonAction.Follow) {
    newFollowList[typeName] = unionArray(newFollowList[typeName], ids);
  } else {
    const unfollowEntityIds = new Set(ids);
    newFollowList[typeName] = newFollowList[typeName].filter(
      entityId => !unfollowEntityIds.has(entityId),
    );
  }
  return newFollowList;
};

export function computeCompletionAction(
  action: FollowButtonAction,
  type: FollowType,
  source?: string,
): NotificationCenterCompletionEvents["action"] {
  const isValidFollowButtonSource = (source: string): source is FollowButtonSource =>
    followButtonSources.includes(source as FollowButtonSource);

  return source && isValidFollowButtonSource(source)
    ? `Notification Center/${typedCapitalize(action)}/${source}/${typedCapitalize(type)}`
    : `Notification Center/${typedCapitalize(action)}/${typedCapitalize(type)}`;
}
