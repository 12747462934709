import { PageType, theme, useGoogleOptimize, useResponsive } from "@product/scmp-sdk";
import { useSyncedRef } from "@react-hookz/web";
import type { OptionsObject } from "notistack";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo } from "react";

import { useCurrentPageType } from "~/lib/router/hooks";

export type Props = {
  duration?: number;
  leftElement: JSX.Element;
  rightElement: JSX.Element;
};

export const useSnackbarSimple = (config?: OptionsObject) => {
  const { enqueueSnackbar } = useSnackbar();

  const configReference = useSyncedRef(config);
  const handleOpenSnackbar = useCallback(
    ({ duration, leftElement, rightElement }: Props) =>
      enqueueSnackbar({
        ...configReference.current,
        autoHideDuration: duration,
        leftElement,
        rightElement,
        style: {
          ...configReference.current?.style,
        },
        variant: "simple",
      }),
    [enqueueSnackbar, configReference],
  );

  return {
    handleOpenSnackbar,
  };
};

export const useAppPromotionSnackbar = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);
  const currentPageType = useCurrentPageType();
  const { getPersonalizationByExperimentId, uiTestingState } = useGoogleOptimize();

  const shouldShow = useMemo(() => {
    const isArticlePage = currentPageType === PageType.Article;
    const isPersonalizationActive = getPersonalizationByExperimentId(
      uiTestingState["FACEBOOK_DOWNLOAD_APP"],
    );
    // cspell: ignore fban fbav
    const isFacebookUserAgent = /fban|fbav/i.test(window.navigator.userAgent);
    return isArticlePage && isPersonalizationActive && isFacebookUserAgent && isMobile;
  }, [currentPageType, getPersonalizationByExperimentId, uiTestingState, isMobile]);

  useEffect(() => {
    if (!shouldShow) return;

    const id = enqueueSnackbar({
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom",
      },
      onClickClose: () => {
        closeSnackbar(id);
      },
      persist: true,
      variant: "appPromotion",
    });

    return () => {
      closeSnackbar(id);
    };
  }, [closeSnackbar, enqueueSnackbar, shouldShow]);
};
