import type { FollowType } from "@product/scmp-sdk";
import { FollowButtonAction, useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import type { PublicConfiguration } from "swr/_internal";
import useSWRImmutable from "swr/immutable";

import { useSnackbarSimple } from "~/components/common/snackbar/hooks";
import { config } from "~/data";
import { accountAtom } from "~/lib/account";
import { useDeferredAction } from "~/lib/hooks";
import { addMinutes, parseArray } from "~/lib/utils";

import { create, fetchList } from "./apis";
import { LastSyncStorageKey, UserFollowSwrFetchKey } from "./consts";
import { SnackbarMessageContainer, SnackbarViewAllLink } from "./styles";
import { generateNewList } from "./utils";

export type UseUserFollowParameters = {
  suspense?: PublicConfiguration["suspense"];
};

export const useUserFollow = (parameters?: UseUserFollowParameters) => {
  const { isLoggedIn, user } = useAtomValue(accountAtom);
  const { set: setLastSync, value: lastSync } = useFallbackStorageValue(LastSyncStorageKey, {
    defaultValue: 0,
  });
  const getIsDataOutDated = () => Date.now() > addMinutes(lastSync ?? 0, 10).getTime();
  const { data, mutate } = useSWRImmutable(isLoggedIn ? UserFollowSwrFetchKey : null, fetchList, {
    isPaused: () => !getIsDataOutDated(),
    onSuccess: () => setLastSync(Date.now()),
    refreshInterval: 1000 * 30,
    revalidateOnMount: true,
    suspense: parameters?.suspense,
  });

  const checkIsFollowed = useCallback(
    (type: FollowType, entityId: string) => !!data?.[`${type}s`].includes(entityId),
    [data],
  );
  const { deferRun } = useDeferredAction([!!data]);
  const { handleOpenSnackbar } = useSnackbarSimple();

  const handleFollowAction = useCallback(
    (
      entityIds: string | string[],
      type: FollowType,
      action: FollowButtonAction,
      callback?: () => void,
    ) => {
      if (!isLoggedIn) return;
      deferRun(async () => {
        const payload = {
          action,
          ids: parseArray(entityIds),
          type,
          uuid: user?.uuid ?? "",
        };
        await mutate(data => create(data, payload), {
          optimisticData: data => generateNewList(data, payload),
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        });
        setLastSync(Date.now());
        const message = action === FollowButtonAction.Follow ? "Following" : "Unfollowed";
        const snackbarParameter = {
          duration: 4000,
          leftElement: <SnackbarMessageContainer>{message}</SnackbarMessageContainer>,
          rightElement: (
            <SnackbarViewAllLink pathname={config.account.scmpAccountHost + "/manage/following"}>
              View all
            </SnackbarViewAllLink>
          ),
        };

        handleOpenSnackbar(snackbarParameter);
        callback?.();
      });
    },
    [isLoggedIn, deferRun, mutate, setLastSync, handleOpenSnackbar, user],
  );

  return {
    checkIsFollowed,
    data,
    handleFollowAction,
    mutate,
  };
};
